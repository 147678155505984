import React, { useState, useContext, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import _find from 'lodash/find'
import _isEqual from 'lodash/isEqual'
import { customMedia } from '../../common/customMedia'
import { MyCarousel } from '../../common/MyCarousel'
import { Image } from '../../common/Image'
import { Acumin } from '../../common/Acumin'
import { SpOnly } from '../../common/SpOnly'
import { PcOnly } from '../../common/PcOnly'

import { StoreContext } from '../../../context/StoreContext'

export interface Props {
  product: any
}

export const HinomaruSocks = (props: Props) => {
  const { product } = props

  const [width, setWidth] = useState(0)

  const SOLDOUT = true

  useEffect(() => {
    const handleResize = () => {
      setWidth(0)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  const {
    options,
    variants,
    variants: [initialVariant]
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)
  const {
    addVariantToCart,
    removeLineItem,
    store: { client, adding, checkout },
  } = useContext(StoreContext)

  // const productVariant = client.product.helpers.variantForOptions(product, variant) || variant
  const productVariant = variant
  const [available, setAvailable] = useState(productVariant.availableForSale)
  const [keyword, setKeyword] = useState('')

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.shopifyId
        )
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [client.product, productVariant.shopifyId, variants]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value)
  }

  const handleOptionChange = (optionIndex, { target }) => {
    const { value } = target
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = _find(variants, ({ selectedOptions }) =>
      _isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
  }

  const handleKeywordChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setKeyword(e.target.value as string)
  }

  const handleAddToCart = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // お一人様一点限りのためカート追加の前に空にする
    await Promise.all(
      checkout.lineItems.map(async lineItem => {
        await removeLineItem(client, checkout.id, lineItem.id)
      })
    )
    await addVariantToCart(productVariant.shopifyId, 1).then(() => {
      // @ts-ignore
      location.href = checkout.webUrl
    })
  }

  const checkDisabled = (name, value) => {
    const match = _find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })
    if (match === undefined) return true
    if (match.availableForSale === true) return false
    return true
  }

  return (
    <>
      <Container>
        <ProductContainer>
          <ProductDetails>
            <SpOnly>
              <Title>HINOMARU<br />SOCKS</Title>
            </SpOnly>
            <PcOnly>
              <Title>HINOMARU<br />SOCKS</Title>
            </PcOnly>
            <Row>
              <div>Price</div>
              <div>{Math.round(product.priceRange.maxVariantPrice.amount).toLocaleString()}<span style={{ fontSize: '12px' }}>（税込・送料別）</span></div>
              <div>数量</div>
              <div>
                <Acumin>
                  <select
                    name='quantity'
                    onChange={event => handleQuantityChange(event)}
                  >
                    <option value={1}>1</option>
                  </select>
                </Acumin>
              </div>
            </Row>
            {/* <SpOnly>
              <div style={{ fontSize: '16px', fontFamily: 'a-otf-midashi-go-mb31-pr6n, sans-serif', textDecoration: 'underline', textAlign: 'center' }}>
                本日18日ローンチイベント限定送料無料
              </div>
            </SpOnly>
            <PcOnly>
              <div style={{ fontSize: '20px', fontFamily: 'a-otf-midashi-go-mb31-pr6n, sans-serif', textDecoration: 'underline' }}>
                本日18日ローンチイベント限定送料無料
              </div>
            </PcOnly> */}
            <Table>
              <tbody>
                <Tr>
                  <Td width={80}>Price</Td>
                  <Td>{Math.round(product.priceRange.maxVariantPrice.amount).toLocaleString()}yen<span style={{ fontSize: '16px' }}>（税込・送料別）</span></Td>
                </Tr>
                <Tr>
                  <Td width={80}>数量</Td>
                  <Td>
                    <Acumin>
                      <select
                        name='quantity'
                        onChange={event => handleQuantityChange(event)}
                      >
                        <option value={1}>1</option>
                      </select>
                    </Acumin>
                  </Td>
                </Tr>
              </tbody>
            </Table>

            <SpOnly>
              <CarouselWrapper>
                <MyCarousel>
                  <ImageWrapper width={width}>
                    <Image path='hinomaru-socks/buyitem1.jpg' />
                  </ImageWrapper>
                  <ImageWrapper width={width}>
                    <Image path='hinomaru-socks/buyitem2.jpg' />
                  </ImageWrapper>
                  <ImageWrapper width={width}>
                    <Image path='hinomaru-socks/buyitem3.jpg' />
                  </ImageWrapper>
                  <ImageWrapper width={width}>
                    <Image path='hinomaru-socks/buyitem4.jpg' />
                  </ImageWrapper>
                </MyCarousel>
              </CarouselWrapper>
            </SpOnly>

            <InfoContainer>
              <Info>
                *HINOMARU SOCKSとたまり醤油のセット販売になります。<br />
                *お餅が付属します。(お餅はキモチです。変更になる場合がございます。)<br />
                *スケートボード及びスケートボーダーは付属しません。
              </Info>
              <Info>
                {`<販売内容(セット)>`}<br />
                <br />
                くつ下:WHIMSY WASHI SOCKS<br />
                焼きもち醤油:たまりや 山川醸造<br />
                <br />
                くつ下 仕様<br />
                WASHI SOCKS<br />
                SIZE : ONE SIZE<br />
                素材:綿 / 分類外繊維(和紙) / その他<br />
                MADE IN JAPAN<br />
                <br />
                焼きもち醤油 仕様<br />
                焼き餅といえば醤油の味が決め手。<br />
                お餅と醤油だけのシンプルな食べ物だけにコクと甘みのバランスを大事に、<br />
                コクのあるたまり醤油と味醂と砂糖で最高の焼き餅を目指しました。<br />
                一度食べたらやめられない味です。<br />
                <br />
                【名称】醤油加工品<br />
                【原材料名】醤油(国内製造)、みりん、砂糖/着色料(カラメル)、(一部に大豆、小麦を含む)<br />
                【内容量】150 ml<br />
                【賞味期限】製造日より1年<br />
                【保存方法】冷暗所にて保存、開栓後はお早めにお召し上がり下さい。
              </Info>
            </InfoContainer>
          </ProductDetails>

          <CarouselWrapper>
            <PcOnly>
              <MyCarousel>
                <ImageWrapper>
                  <Image path='hinomaru-socks/buyitem1.jpg' />
                </ImageWrapper>
                <ImageWrapper>
                  <Image path='hinomaru-socks/buyitem2.jpg' />
                </ImageWrapper>
                <ImageWrapper>
                  <Image path='hinomaru-socks/buyitem3.jpg' />
                </ImageWrapper>
                <ImageWrapper>
                  <Image path='hinomaru-socks/buyitem4.jpg' />
                </ImageWrapper>
              </MyCarousel>
            </PcOnly>
          </CarouselWrapper>
        </ProductContainer>

        <BtoNoticeContainer>
          <div>数量限定販売</div>
          <div>
            販売期間：2020年12月21日（日）〜<br />
            発送時期：オーダー受付から1週程度で発送<br />
            ＊12月21日〜1月4日までの初回出荷受付分は1月5日頃に発送を予定しております。以降のご注文は受付から1週間程度で随時発送。<br />
            ＊販売予定数量に達し次第、予告なく受付を終了させて頂きます。<br />
            ＊お客様のご都合による返品・交換はお受けしかねます。予めご了承ください。<br />
          </div>
        </BtoNoticeContainer>

        <form onSubmit={handleAddToCart}>
          <ButtonWrapper>
            <AddToCartButton type="submit" disabled={!available || adding || SOLDOUT}>
              <Acumin>{SOLDOUT ? 'SOLD OUT' : 'BUY'}</Acumin>
              <Shadow />
            </AddToCartButton>
          </ButtonWrapper>
        </form>

      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 40px auto 0;
    width: 1000px;
  `}
`

const ProductContainer = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  `}
`

const ProductDetails = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 50px;
  `}

  position: relative;
`

const Title = styled.div`
  font-family: 'EB Garamond', serif;
  font-size: 4rem;
  
  ${customMedia.lessThan("medium")`
    font-size: 3rem;
  `}
  ${customMedia.greaterThan("medium")`
    span {
      font-size: 8rem;
    }
  `}
`

const Row = styled.div`
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  width: 80%;

  * {
    font-family: 'EB Garamond', serif;
    font-size: 1.4rem;

    :nth-child(3) {
      font-size: 1rem;
    }
  }
  
  ${customMedia.greaterThan("medium")`
    display: none;
  `}
`

const Table = styled.table`
  ${customMedia.lessThan("medium")`
    display: none;
  `}

  * {
    font-size: 2.5rem;
  }

  table-layout: fixed;
`

const Tr = styled.tr`
  font-family: 'EB Garamond', serif;
  
  ${customMedia.greaterThan("medium")`
    height: 40px;

    :nth-of-type(2) {
      td {
        font-size: 2rem;
      }
    }
  `}

  :nth-of-type(2) {
    font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
`

const Td = styled.td`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
`

const InfoContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    margin-top: 60px;

    div:nth-child(2) {
      margin-top: 240px;
    }
  `}

  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -1px;
`

const Info = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.4rem;
    margin-top: 20px;
  `}
`

const SizeContainer = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    width: 190px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 60px;
    width: 380px;
  `}

  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
`

const Size = styled.div`
  ${customMedia.lessThan("medium")`
    height: 25px;
    line-height: 25px;

    span {
      font-size: 1.2rem;
      width: 20px;

      :first-child {
        text-align: center;
        margin-right: 10px;
      }
    }
  `}
  ${customMedia.greaterThan("medium")`
    height: 40px;
    line-height: 40px;

    span {
      font-size: 2.4rem;
      margin-right: 20px;
      width: 34px;

      :first-child {
        text-align: center;
        margin-right: 18px;
      }

      :last-child {
        margin-right: 0;
      }
    }
  `}

  border-bottom: 1px #000 solid;

  :nth-child(3) {
    border: none;
  }
  
  span {
    display: inline-block;
  }

  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: center;
  flex-wrap: center;
  -webkit-align-items: center;
  align-items: center;
`

const CarouselWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 10px 0 0;

    ul.control-dots {
      position: relative;
    }
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 60px;
    width: 48%;

    ul.control-dots {
      position: relative;
      text-align: center;
    }
  `}

  li {
    :focus {
      outline: 0;
    }
  }

  li.slide, li.slide.selected {
    background-color: #fff;
  }
`

const ImageWrapper = styled.div<{ width?: number }>`
  ${props => customMedia.lessThan("medium")`
    margin: 0 auto;
    height: ${props.width - 40}px;
    width: ${props.width - 40}px;

    img {
      height: ${props.width - 40}px !important;
      width: ${props.width - 40}px !important;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 400px;
    margin: 0 auto;
  `}
`

const BtoNoticeContainer = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    margin-top: 20px;

    div {
      :nth-child(1) {
        padding: 2px 5px 2.5px;
        margin-bottom: 5px;
        width: 80px;
      }
      :nth-child(2) {
        font-size: 0.8rem;
      }
      :nth-child(3) {
        font-size: 0.6rem;
      }
    }
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 50px;

    div {
      :nth-child(1) {
        padding: 4.5px 10px 6px;
        margin-bottom: 10px;
        width: 120px;
      }
      :nth-child(3) {
        font-size: 1.2rem;
        margin-top: 20px;
      }
    }
  `}

  div {
    :nth-child(1) {
      background-color: #000;
      color: #fff;
      text-align: center;
    }
  }

  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0.5px;
`

const KeywordFormContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 40px;
  `}

  text-align: center;
  position: relative;
`

const KeywordDescription = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 0.8em;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.4rem;
    margin-top: 40px;
  `}

  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0.5px;
  text-align: center;
`

const VideoWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 10px;

    video {
      width: 100%;
    }
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 20px;

    video {
      width: 600px;
    }
  `}
`

// const DummyVideo = styled.div`
//   ${customMedia.lessThan("medium")`
//     margin: 10px 0;
//     width: 100%;
//   `}
//   ${customMedia.greaterThan("medium")`
//     border: 1px #000 solid;
//     margin: 20px auto;
//     height: 337.5px;
//     width: 600px;
//   `}
// `

const KeywordInput = styled.input`
  ${customMedia.lessThan("medium")`
    font-size: 0.8rem;
    height: 20px;
    margin-top: 10px;
    padding: 2.5px 5px 0;
    width: 150px;

    ::placeholder {
      font-size: 0.8rem;
    }
    
    :-ms-input-placeholder {
      font-size: 0.8rem;
    }
    
    ::-ms-input-placeholder {
      font-size: 0.8rem;
    }
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.4rem;
    height: 40px;
    margin-top: 40px;
    padding: 5px 10px 0;
    width: 200px;

    ::placeholder {
      font-size: 1.4rem;
    }
    
    :-ms-input-placeholder {
      font-size: 1.4rem;
    }
    
    ::-ms-input-placeholder {
      font-size: 1.4rem;
    }
  `}

  background-color: #cdcdcd;
  border: none;
  font-weight: bold;

  ::placeholder {
    color: #fff;
    opacity: 1;
    text-align: center;
  }
  
  :-ms-input-placeholder {
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  
  ::-ms-input-placeholder {
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
`

const ButtonWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  text-align: center;
`

const AddToCartButton = styled.button`
  ${customMedia.lessThan("medium")`
    border: 2px #000 solid;
    height: 26px;
    margin-top: 20px;
    width: 88px;

    span {
      font-size: 1.75rem;
    }
  `}
  ${customMedia.greaterThan("medium")`  
    border: 4px #000 solid;
    height: 45px;
    margin-top: 40px;
    width: 140px;

    span {
      font-size: 3.5rem;
    }
  `}
  
  background-color: #fff;
  cursor: pointer;
  color: #000;
  font-family: 'AcuminVariableConcept';
  font-variation-settings: 'wght' 550, 'wdth' 55;
  font-weight: bold;
  position: relative;
  text-align: center;

  :focus {
    outline: 0;
  }
`

const Shadow = styled.div`
  ${customMedia.lessThan("medium")`
    height: 26px;
    top: 2px;
    left: 2px;
    width: 88px;
  `}
  ${customMedia.greaterThan("medium")`
    height: 45px;
    top: 4px;
    left: 4px;
    width: 140px;
  `}

  background-color: #000;
  position: absolute;
  z-index: -1;
`
