import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { Image } from '../../common/Image'
import { Acumin } from '../../common/Acumin'
import { Window } from '../../common/Window'

export const EgglifeT = () => {
  const [height, setHeight] = useState(0)
  const [productNumber, setProductNumber] = useState(1000)

  useEffect(() => {
    const handleResize = () => {
      setHeight(0)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    setProductNumber(3)
  }, [])

  return (
    <>
      <Container productNumber={productNumber}>
        <Slider productNumber={productNumber}>
          <ProductCard>
            <Window heightSp={205} widthSp={160} heightPc={390} widthPc={300}>
              <WindowContent>
                <ProductImageWrapper width='80%'>
                  <Image path='egglife-t/5.jpg' />
                </ProductImageWrapper>
              </WindowContent>
            </Window>
          </ProductCard>
          <ProductCard>
            <Window heightSp={205} widthSp={160} heightPc={390} widthPc={300}>
              <WindowContent>
                <ProductImageWrapper width='100%'>
                  <Image path='egglife-t/7.jpg' />
                </ProductImageWrapper>
              </WindowContent>
            </Window>
          </ProductCard>
          <ProductCard>
            <Window heightSp={205} widthSp={160} heightPc={390} widthPc={300}>
              <WindowContent>
                <ProductImageWrapper width='80%'>
                  <Image path='egglife-t/8.jpg' />
                </ProductImageWrapper>
              </WindowContent>
            </Window>
          </ProductCard>
        </Slider>
        <ProductTitleContainer height={height}>
          <ProductTitleContent>
            <Title>
              <Acumin white>EGGLIFE T</Acumin>
            </Title>
            <Number>
              <Acumin white>NO.</Acumin>
              <Acumin white>01</Acumin>
            </Number>
          </ProductTitleContent>
        </ProductTitleContainer>
      </Container>
    </>
  )
}

const Container = styled.div<{productNumber: number}>`
  ${customMedia.lessThan("medium")`
    margin-top: 40px;
    overflow-x: scroll;
    
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display:none;
    }
  `}
  ${props => customMedia.greaterThan("medium")`
    border-top: 1px #000 solid;
    border-bottom: 1px #000 solid;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    height: calc((430px + 45px) * ${props.productNumber});
    margin: 0 auto;
    width: 80%;
  `}
`

const Slider = styled.div<{productNumber: number}>`
  ${props => customMedia.lessThan("medium")`
    height: 230px;
    width: calc((160px + 20px) * ${props.productNumber});
  `}
  ${customMedia.greaterThan("medium")`
    margin-right: 20px;
  `}
`

const ProductTitleContainer = styled.div<{height: number}>`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
  ${props => customMedia.greaterThan("medium")`
    background-color: #000;
    height: ${props.height}px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 50%;
  `}
`

const ProductTitleContent = styled.div`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
  ${customMedia.greaterThan("medium")`
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  `}
`

const Title = styled.div`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 6rem;
    font-variation-settings: 'wght' 600, 'wdth' 35;
    margin: 50px 0;

    span {
      margin-right: 10px;
    }
  `}

  color: #ffffff;
  font-family: 'AcuminVariableConcept';
  font-weight: bold;
`

const Number = styled.div`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 5rem;
    font-variation-settings: 'wght' 550, 'wdth' 65;
    margin: 50px 0;
    text-align: center;
    
    span {
      :first-child {
        font-size: 3rem;
      }
    }
  `}

  color: #ffffff;
  font-family: 'AcuminVariableConcept';
  font-weight: bold;
`

const ProductCard = styled.div`
  ${customMedia.lessThan("medium")`
    float: left;
    margin: 0 10px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 40px 0;

    > div {
      margin: 0 auto;
    }
  `}
`

const WindowContent = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const ProductImageWrapper = styled.div<{width: string}>`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: ${props => props.width};
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: ${props => props.width};

    img {
      object-fit: cover;
    }
  `}
`