import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { Image } from '../../common/Image'
import { Acumin } from '../../common/Acumin'
import { SpOnly } from '../../common/SpOnly'
import { PcOnly } from '../../common/PcOnly'

export const HinomaruSocks = () => {
  const [width, setWidth] = useState(0)
  const [showPhone, setShowPhone] = useState(false)
  const [hidePhone, setHidePhone] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWidth(0)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  const handleClickPlayButton = () => {
    if (!showPhone && !hidePhone) {
      setShowPhone(true)
    } else {
      setShowPhone(!showPhone)
      setHidePhone(!hidePhone)
    }
  }

  return (
    <>
      <OverflowHidden>
        <Container>
          <div className="section1">
            <Image path='hinomaru-socks/mainitem.jpg' />

            <div className="content">
              <div className="title">HINOMARU SOCKS</div>
              <div className="description">
                スケートボードをバックボーンに持つ『WHIMSY』が、日本の和紙を使ったソックスをドロップ！
                <br />
                海外から発信されて僕たちが受け取ったカルチャーが、各地のストリートで育って大きく花ひらく、まさに今のこのタイミングで、昔から親しんできた和紙との融合によって“HINOMARU SOCKS”が誕生しました。そんな和の心はしやうゆに在りと、彼の千利休も言ってないとか言わないとか。とにかく、日出る国の僕たちはお醤油には目がありません。たまり醤油の伝統と可能性を無限に追求する醸造所『たまりや』とのコラボはもはや、カルチャー的運命の邂逅に間違いございません。
                <br />
                人種なんて、場所なんて、誰かの決めた限界なんて、ぜんぜん関係ない。ボーダレスに夢見るストリートドリームスには、実際の餅より、絵に描いた餅を。日の丸日本の未来を想い描く、靴下とたまり醤油のたまらないセットアイテムです。
              </div>
            </div>
          </div>

          <SpOnly>
            <div className="section2">
              <Image path='hinomaru-socks/shopitem1.jpg' />

              <div className="shop">
                <Image path='hinomaru-socks/shop.jpg' />

                <div className='shop-content'>
                  <div className="title">Socks Brand</div>
                  <div className="name">WHIMSY</div>
                  <div className="description">
                    スケートボーダーが作るスケーターのためのブランド。ソックスをキャンバスに、スケート、グラフィティ、カリグラフィーアートなどを落とし込み、デイリーユースな下着をコレクティヴに魅せるブランディングで唯一無二の存在感を放っています。
                  </div>
                  <div className="instagram"><span>Instagram：</span> <a href="https://instagram.com/whimsysocks" target="_blank">@whimsysocks</a></div>
                </div>
              </div>
            </div>
          </SpOnly>

          <PcOnly>
            <div className="section2">
              <div className="content">
                <Image path='hinomaru-socks/shop.jpg' />

                <div className="title">Socks Brand</div>
                <div className="name">WHIMSY</div>
                <div className="description">
                  スケートボーダーが作るスケーターのためのブランド。ソックスをキャンバスに、スケート、グラフィティ、カリグラフィーアートなどを落とし込み、デイリーユースな下着をコレクティヴに魅せるブランディングで唯一無二の存在感を放っています。
                </div>
                <div className="instagram"><span>Instagram：</span> <a href="https://instagram.com/whimsysocks" target="_blank">@whimsysocks</a></div>
              </div>

              <div className="images">
                <Image path='hinomaru-socks/shopitem1.jpg' />
              </div>
            </div>
          </PcOnly>

          <div className="section3">
            <Image path='hinomaru-socks/shopitem2.jpg' />

            <div className="content">
              <Image path='hinomaru-socks/shop2.jpg' />

              <div>
                <div className="title">たまり醤油醸造所</div>
                <div className="name">たまりや</div>
                <div className="description">
                  自然林で覆われた金華山と、その麓を流れる岐阜市民の心のシンボル清流・長良川。歴史と自然が息づく街で、山川醸造は1943年の創業以来いまも昔と変わらず木桶を用いた天然醸造で美濃の伝統的な「たまり醤油」と「豆味噌」を作っている醸造所です。それも、ただ伝統にだけとどまるのではなく「アイスクリームにかける醤油」や「もこもこ泡醤油」などなど、たまり醤油の可能性を広げる一風変わった商品開発にも取り組んでいます。環境が大きく変化していく中で、これからも変わらずに木桶仕込みのたまり醤油の美味しさも楽しさも、無限大の可能性と個性を伝えています。
                </div>
                <div className="instagram"><span>Instagram：</span><a href="https://instagram.com/tamariya_gifu" target="_blank">@tamariya_gifu</a></div>
              </div>
            </div>
          </div>
        </Container>
      </OverflowHidden>
    </>
  )
}

const OverflowHidden = styled.div`
  overflow: hidden;
`

const Container = styled.div`
  .instagram {
    a {
      color: #000;
    }
  }

  @media screen and ( max-width: 768px ) {
    padding: 0 20px;

    .instagram {
      font-size: 0.8rem;
      margin: 5px 0 20px;

      span {
        font-weight: bold;
      }
    }
  }

  @media screen and ( min-width: 769px ) {
    margin: 200px auto 0;
    width: 1000px;

    .instagram {
      font-size: 1.2rem;
      margin: 10px 0 100px;

      span {
        font-weight: bold;
      }
    }
  }

  .title, .name {
    font-family: 'EB Garamond', serif;
    font-weight: bold;
    letter-spacing: -1px;
  }
  
  .title {
    @media screen and ( max-width: 768px ) {
      font-size: 1rem;
    }
    @media screen and ( min-width: 769px ) {
      font-size: 2rem;
      margin-bottom: -14px;
    }
  }

  .name {
    @media screen and ( max-width: 768px ) {
      font-size: 3rem;
    }
    @media screen and ( min-width: 769px ) {
      font-size: 4rem;
    }
  }

  .description {
    font-family: 'TsukuBRdGothic-Regular',sans-serif;

    @media screen and ( max-width: 768px ) {
      font-size: 1.2rem;
    }
    @media screen and ( min-width: 769px ) {
      font-size: 1.5rem;
      line-height: 2.8rem;
    }
  }

  .section1 {
    @media screen and ( max-width: 768px ) {
      .gatsby-image-wrapper {
        margin-top: 20px;
      }
    }
    @media screen and ( min-width: 769px ) {
      .gatsby-image-wrapper {
        margin: 0 auto;
        width: 800px;
      }
    }

    .content {
      @media screen and ( max-width: 768px ) {
        .title {
          font-size: 28px;
          margin-bottom: 8px;
        }
      }
      @media screen and ( min-width: 769px ) {
        .title {
          font-size: 48px;
          text-align: center;
          margin-bottom: 20px;
        }
        margin: 0 auto;
        width: 665px;
      }
    }
  }

  .section2 {
    @media screen and ( max-width: 768px ) {
      .artist, .shop {
        display: flex;

        .gatsby-image-wrapper {
          width: 40%;

          img {
            height: auto !important;
            object-fit: fill !important;
          }
        }

        .artist-content, .shop-content {
          padding-left: 10px;
          width: 60%;
        }
      }

      .gatsby-image-wrapper {
        margin: 20px auto;
        width: 60%;
      }
    }
    @media screen and ( min-width: 769px ) {
      display: flex;
      justify-content: space-between;
      margin-top: 100px;

      .content {
        margin-top: 80px;
        width: 50%;

        .gatsby-image-wrapper {
          margin-bottom: 20px;
          width: 400px;
        }
      }

      .images {
        width: 45%;
        
        .gatsby-image-wrapper {
          margin-bottom: 20px;
        }
      }
    }
  }

  .section3 {
    .content {
      .title, .name {
        font-family: 'TsukuBRdGothic-Regular',sans-serif;
        font-weight: 600;
      }
    }
    
    @media screen and ( max-width: 768px ) {
      .gatsby-image-wrapper {
        margin: 0 auto;
        width: 80%;
      }

      .content {
        display: flex;

        .gatsby-image-wrapper {
          width: 35%;

          img {
            height: auto !important;
            object-fit: fill !important;
          }
        }

        > div {
          padding-left: 20px;
          width: 65%;
        }
      }

      .gatsby-image-wrapper {
        margin: 20px auto;
        width: 50%;
      }
    }
    @media screen and ( min-width: 769px ) {
      display: flex;
      justify-content: space-between;

      .gatsby-image-wrapper {
        width: 36%;
  
        img {
          height: auto !important;
          object-fit: fill !important;
        }
      }
  
      .content {
        margin-top: 60px;
        width: 50%;
  
        .gatsby-image-wrapper {
          margin-bottom: 20px;
          width: 70%;
        }
      }
    }
  }
`




const ProductContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 40px 20px 0;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
`

const Image4Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    -webkit-flex-basis: 100px;
    flex-basis: 100px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    -webkit-flex-basis: 300px;
    flex-basis: 300px;
  `}

  flex: none;
  position: relative;
`

const ProductDetails = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 0 0 50px;
  `}

  position: relative;
`

const ProductName = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const ProductDescription = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 15px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
  `}

  font-family: 'TsukuBRdGothic-Regular',sans-serif;
  text-align: justify;
`

const BoxGifWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 40px;
    width: 130px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 50px 0 0 160px;
    width: 360px;
  `}
`

const ArtistAndShopContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 50px 0;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-top: 50px;
  `}
`

const ArtistAndShopDetails = styled.div`
  ${customMedia.lessThan("medium")`
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  z-index: 99;
`

const ArtistAndShopDetailContainer = styled.div`
${customMedia.lessThan("medium")`
  margin: 0 20px;
`}
${customMedia.greaterThan("medium")`
`}

z-index: 99;
`

const ArtistAndShopTitle = styled.div`
  ${customMedia.lessThan("medium")`
    * { font-size: 1rem; }
    height: 8px;
  `}
  ${customMedia.greaterThan("medium")`
    * { font-size: 2rem; }
    height: 12px;
    margin-top: 10px;
  `}
`

const ArtistAndShopNameEn = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Image5Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 20px;
    width: 100px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 100px;
    width: 300px;
  `}

  flex: none;
  z-index: -1;
`

const Image6Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 120px;

    div {
      font-size: 8px;
      margin-top: 5px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 280px;
    margin-right: 48px;

    div {
      font-size: 1.4rem;
    }
  `}

  flex: none;
  text-align: center;
`

const Image7Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 20px;
    width: 120px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 100px;
    width: 300px;
  `}

  flex: none;
  z-index: -1;
`

const ArtistAndShopDescription = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 15px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
    margin-right: 100px;
    width: 468px;
  `}

  font-family: 'TsukuBRdGothic-Regular',sans-serif;
  text-align: justify;
`

const Image8Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 250px;

    div {
      font-size: 8px;
      margin-top: 5px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 400px;
    margin-top: 60px;

    div {
      font-size: 1.4rem;
    }
  `}

  flex: none;
  text-align: center;
`

const Instagram = styled.div<{ align?: string }>`
  ${customMedia.lessThan("medium")`
    font-size: 0.8rem;
    margin-top: 10px;

    span {
      font-size: 6px;
    }
  `}
  ${props => customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    ${props.align && `text-align: ${props.align}`};
    
    span {
      font-size: 1.2rem;
    }
  `}

  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  text-align: left;

  span {
    font-weight: bold;
  }
`