import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { Image } from '../../common/Image'
import { Acumin } from '../../common/Acumin'
import { SpOnly } from '../../common/SpOnly'
import { PcOnly } from '../../common/PcOnly'

const boxGifSrc = require('../../../images/studs/box.gif')

export const Studs = () => {
  const [width, setWidth] = useState(0)
  const [showPhone, setShowPhone] = useState(false)
  const [hidePhone, setHidePhone] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWidth(0)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  const handleClickPlayButton = () => {
    if (!showPhone && !hidePhone) {
      setShowPhone(true)
    } else {
      setShowPhone(!showPhone)
      setHidePhone(!hidePhone)
    }
  }
  
  return (
    <>
      <OverflowHidden>
        <Container>
          <ProductContainer>
            <Image4Wrapper>
              <Image path='studs/4.jpg' />
            </Image4Wrapper>
            <ProductDetails>
              <ProductName>
                <Acumin>STuds</Acumin>
              </ProductName>
              <ProductDescription>
                泣く子もだまっちゃうコーデックスカルチャーの伝道師『BOTANIZE』の力作、鉢とロックグラスのセットがお目見えです。底におなじみの水□孔が施された鉢にお気に入りの植物を入れて、もう一つはロックグラスにしたり小物入れにしたり、反応！変応！自由自在！あなたらしさを受け入れるおそろいの器になります。ICMが世界で1番おいしいと思うジャーキー屋さん『NICK JERKY』の、ぎゅっと旨味を熟成させた神戸牛ジャーキーをスモークしたスペシャルな一皿をおつまみに、INSPIRATION CULT MARKETだけの世にも珍奇な“STuds”が完成しました。きっときっと、あなたのライフタイムに特別なひとときが開花することでしょう。
              </ProductDescription>
              <BoxGifWrapper>
                <img src={boxGifSrc} alt={boxGifSrc} />
              </BoxGifWrapper>
            </ProductDetails>
          </ProductContainer>

          <ArtistAndShopContainer>
            <SpOnly>
              <Image6Wrapper>
                <Image path='studs/6.jpg' />
              </Image6Wrapper>
            </SpOnly>

            <ArtistAndShopDetails>
              <Image5Wrapper>
                <Image path='studs/5.jpg' />
              </Image5Wrapper>

              <ArtistAndShopDetailContainer>
                <ArtistAndShopTitle>
                  <Acumin light>ARTIST</Acumin>
                </ArtistAndShopTitle>
                <ArtistAndShopNameEn>
                  <Acumin>BOTANIZE</Acumin>
                </ArtistAndShopNameEn>

                <ArtistAndShopDescription>
                  巷の高感度オサレさんたちに愛されるコーデックスカルチャーの火付け役、横町 健さんこと大の植物好き&柔術家&お弁当好き&ファッションラバー&クルマ好き&愛犬家&&&&。ぜんぜん語り尽くせないくらいの魅力にあふれるKENさんとEXOTIC PLANTS SHOP『BOTANIZE』。塊根植物（コーデックス）を中心としたボタナイズなライフスタイルだけではなく、一挙手一投足に注目が集まる、みんなのカルチャーヒーロー。現代ニッポンの一人一人に宿るユニークな個性を育むリアルライフの充実への最短近道は『BOTANIZE』からはじまります。
                  <Instagram><span>Instagram:</span> @botanize_official, @aneken</Instagram>
                </ArtistAndShopDescription>
              </ArtistAndShopDetailContainer>
            </ArtistAndShopDetails>

            <PcOnly>
              <Image6Wrapper>
                <Image path='studs/6.jpg' />
              </Image6Wrapper>
            </PcOnly>
          </ArtistAndShopContainer>

          <ArtistAndShopContainer>
            <SpOnly>
              <Image8Wrapper>
                <Image path='studs/8.jpg' />
              </Image8Wrapper>
            </SpOnly>

            <ArtistAndShopDetails>
              <Image7Wrapper>
                <Image path='studs/7.jpg' />
              </Image7Wrapper>

              <ArtistAndShopDetailContainer>
                <ArtistAndShopTitle>
                  <Acumin light>SHOP</Acumin>
                </ArtistAndShopTitle>
                <ArtistAndShopNameEn>
                  <Acumin>NICKJERKY</Acumin>
                </ArtistAndShopNameEn>

                <ArtistAndShopDescription>
                  肉職人だからこそ丁寧に積み重ねてきた本当においしいお肉への追求。そして、“ならでは”のファイナルアンサーとしてたどり着いた熟成肉のクラフトジャーキー。天然の海塩だけを添えてお肉の美味しさを無限大に引き出すことだけを考えて生まれた『NICK JERKY』は、噛みしめるほどお肉の味がするするしまくります。いつの時代も舌とこころに響くジャーキーを神戸からお届けしています。
                  <Instagram><span>Instagram:</span> @nickjerkykobe</Instagram>
                </ArtistAndShopDescription>
              </ArtistAndShopDetailContainer>
            </ArtistAndShopDetails>

            <PcOnly>
              <Image8Wrapper>
                <Image path='studs/8.jpg' />
              </Image8Wrapper>
            </PcOnly>
          </ArtistAndShopContainer>

        </Container>
      </OverflowHidden>
    </>
  )
}

const OverflowHidden = styled.div`
  overflow: hidden;
`

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin: 80px auto 0;
    width: 960px;
  `}
`

const ProductContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 40px 20px 0;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
`

const Image4Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    -webkit-flex-basis: 100px;
    flex-basis: 100px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    -webkit-flex-basis: 300px;
    flex-basis: 300px;
  `}

  flex: none;
  position: relative;
`

const ProductDetails = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 0 0 50px;
  `}

  position: relative;
`

const ProductName = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const ProductDescription = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 15px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
  `}

  font-family: 'TsukuBRdGothic-Regular',sans-serif;
  text-align: justify;
`

const BoxGifWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 40px;
    width: 130px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 50px 0 0 160px;
    width: 360px;
  `}
`

const ArtistAndShopContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 50px 0;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-top: 50px;
  `}
`

const ArtistAndShopDetails = styled.div`
  ${customMedia.lessThan("medium")`
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  z-index: 99;
`

const ArtistAndShopDetailContainer = styled.div`
${customMedia.lessThan("medium")`
  margin: 0 20px;
`}
${customMedia.greaterThan("medium")`
`}

z-index: 99;
`

const ArtistAndShopTitle = styled.div`
  ${customMedia.lessThan("medium")`
    * { font-size: 1rem; }
    height: 8px;
  `}
  ${customMedia.greaterThan("medium")`
    * { font-size: 2rem; }
    height: 12px;
    margin-top: 10px;
  `}
`

const ArtistAndShopNameEn = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Image5Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 20px;
    width: 100px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 100px;
    width: 300px;
  `}

  flex: none;
  z-index: -1;
`

const Image6Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 120px;

    div {
      font-size: 8px;
      margin-top: 5px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 280px;
    margin-right: 48px;

    div {
      font-size: 1.4rem;
    }
  `}

  flex: none;
  text-align: center;
`

const Image7Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 20px;
    width: 120px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 100px;
    width: 300px;
  `}

  flex: none;
  z-index: -1;
`

const ArtistAndShopDescription = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 15px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
    margin-right: 100px;
    width: 468px;
  `}

  font-family: 'TsukuBRdGothic-Regular',sans-serif;
  text-align: justify;
`

const Image8Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 250px;

    div {
      font-size: 8px;
      margin-top: 5px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 400px;
    margin-top: 60px;

    div {
      font-size: 1.4rem;
    }
  `}

  flex: none;
  text-align: center;
`

const Instagram = styled.div<{align?: string}>`
  ${customMedia.lessThan("medium")`
    font-size: 0.8rem;
    margin-top: 10px;

    span {
      font-size: 6px;
    }
  `}
  ${props => customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    ${props.align && `text-align: ${props.align}`};
    
    span {
      font-size: 1.2rem;
    }
  `}

  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  text-align: left;

  span {
    font-weight: bold;
  }
`