import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'

import { Home } from '../components/Home'
import { PaloSanto as PaloSantoList } from '../components/ProductList/palo-santo'
import { HinomaruSocks as HinomaruSocksList } from '../components/ProductList/hinomaru-socks'
import { BrooklynMachineWorksGangstaUcchieEdition as BrooklynMachineWorksGangstaUcchieEditionList } from '../components/ProductList/brooklyn-machine-works-gangsta-ucchie-edition'
import { Studs as StudsList } from '../components/ProductList/studs'
import { EgglifeT as EgglifeTList } from '../components/ProductList/egglife-t'
import { PaloSanto as PaloSantoDetail } from '../components/ProductDetail/palo-santo'
import { HinomaruSocks as HinomaruSocksDetail } from '../components/ProductDetail/hinomaru-socks'
import { BrooklynMachineWorksGangstaUcchieEdition as BrooklynMachineWorksGangstaUcchieEditionDetail } from '../components/ProductDetail/brooklyn-machine-works-gangsta-ucchie-edition'
import { Studs as StudsDetail } from '../components/ProductDetail/studs'
import { EgglifeT as EgglifeTDetail } from '../components/ProductDetail/egglife-t'
import { PaloSanto as PaloSantoForm } from '../components/ProductForm/palo-santo'
import { HinomaruSocks as HinomaruSocksForm } from '../components/ProductForm/hinomaru-socks'
import { BrooklynMachineWorksGangstaUcchieEdition as BrooklynMachineWorksGangstaUcchieEditionForm } from '../components/ProductForm/brooklyn-machine-works-gangsta-ucchie-edition'
import { Studs as StudsForm } from '../components/ProductForm/studs'
import { EgglifeT as EgglifeTForm } from '../components/ProductForm/egglife-t'

import { Archive } from '../components/Archive'

export interface Props {
  data: any
}

export const query = graphql`
  query($handle: String) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        sku
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }
  }
`

const ProductPage = (props: Props) => {
  const { data } = props
  const product = data.shopifyProduct

  return (
    <Layout>
      <SEO
        title={`${product.title} | ICMarket`}
        description={product.description}
        keywords={[]} />
      <H1></H1>
      <Home />
      {product.title === 'asebi Palo Santo + Palo Santo tray drawing by UE' ? (
        <>
          <PaloSantoList />
          <PaloSantoDetail />
          <PaloSantoForm product={product} />
        </>
      ) : product.title === 'HINOMARU SOCKS' ? (
        <>
          <HinomaruSocksList />
          <HinomaruSocksDetail />
          <HinomaruSocksForm product={product} />
        </>
      ) : product.title === 'BROOKLYN MACHINE WORKS GANGSTA "UCCHIE" edition' ? (
        <>
          <BrooklynMachineWorksGangstaUcchieEditionList />
          <BrooklynMachineWorksGangstaUcchieEditionDetail />
          <BrooklynMachineWorksGangstaUcchieEditionForm product={product} />
        </>
      ) :
        product.title === 'STuds' ? (
          <>
            <StudsList />
            <StudsDetail />
            <StudsForm product={product} />
          </>
        ) :
          product.title === 'EGGLIFE T' ? (
            <>
              <EgglifeTList />
              <EgglifeTDetail />
              <EgglifeTForm product={product} />
            </>
          ) :
            ''}
      <Archive />
    </Layout>
  )
}

const H1 = styled.h1`
  font-size: 1.8rem;
`

export default ProductPage