import React, { useState, useContext, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import _find from 'lodash/find'
import _isEqual from 'lodash/isEqual'
import { customMedia } from '../../common/customMedia'
import { MyCarousel } from '../../common/MyCarousel'
import { Image } from '../../common/Image'
import { Acumin } from '../../common/Acumin'
import { SpOnly } from '../../common/SpOnly'
import { PcOnly } from '../../common/PcOnly'

import { StoreContext } from '../../../context/StoreContext'

export interface Props {
  product: any
}

export const EgglifeT = (props: Props) => {
  const { product } = props

  const [width, setWidth] = useState(0)

  const SOLDOUT = true

  useEffect(() => {
    const handleResize = () => {
      setWidth(0)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  const {
    options,
    variants,
    variants: [initialVariant]
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)
  const {
    addVariantToCart,
    store: { client, adding, checkout },
  } = useContext(StoreContext)

  // const productVariant = client.product.helpers.variantForOptions(product, variant) || variant
  const productVariant = variant
  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.shopifyId
        )
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [client.product, productVariant.shopifyId, variants]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value)
  }

  const handleOptionChange = (optionIndex, { target }) => {
    const { value } = target
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = _find(variants, ({ selectedOptions }) =>
      _isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
  }

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, quantity).then(() => {
      // @ts-ignore
      window.open(checkout.webUrl)
    })
  }

  const checkDisabled = (name, value) => {
    const match = _find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })
    if (match === undefined) return true
    if (match.availableForSale === true) return false
    return true
  }

  return (
    <>
      <Container>
        <ProductContainer>
          <ProductDetails>
            <Title><Acumin>{product.title}</Acumin></Title>
            <Row>
              <div><Acumin>Price</Acumin></div>
              <div><Acumin>{Math.round(product.priceRange.maxVariantPrice.amount).toLocaleString()}yen</Acumin></div>
              <div><Acumin>Color</Acumin></div>
              <div>◯</div>
              {options.map(({ id, name, values }, index) => (
                <React.Fragment key={id}>
                  <div><label htmlFor={name}><Acumin>{name}</Acumin></label></div>
                  <div>
                    <Acumin>
                      <select
                        name={name}
                        key={id}
                        onChange={event => handleOptionChange(index, event)}
                      >
                        {values.map(value => (
                          <option
                            value={value}
                            key={`${name}-${value}`}
                            disabled={checkDisabled(name, value)}
                          >
                            {value}
                          </option>
                        ))}
                      </select>
                    </Acumin>
                  </div>
                </React.Fragment>
              ))}
              <div>数量</div>
              <div>
                <Acumin>
                  <select
                    name='quantity'
                    onChange={event => handleQuantityChange(event)}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                  </select>
                </Acumin>
              </div>
            </Row>
            <Table>
              <tbody>
                <Tr>
                  <Td width={80}><Acumin>Price</Acumin></Td>
                  <Td><Acumin>{Math.round(product.priceRange.maxVariantPrice.amount).toLocaleString()}yen</Acumin></Td>
                </Tr>
                <Tr>
                  <Td width={80}><Acumin>Color</Acumin></Td>
                  <Td>◯</Td>
                </Tr>
                {options.map(({ id, name, values }, index) => (
                  <React.Fragment key={id}>
                    <Tr>
                      <Td width={80}><label htmlFor={name}><Acumin>{name}</Acumin></label></Td>
                      <Td>
                        <Acumin>
                          <select
                            name={name}
                            key={id}
                            onChange={event => handleOptionChange(index, event)}
                          >
                            {values.map(value => (
                              <option
                                value={value}
                                key={`${name}-${value}`}
                                disabled={checkDisabled(name, value)}
                              >
                                {value}
                              </option>
                            ))}
                          </select>
                        </Acumin>
                      </Td>
                    </Tr>
                  </React.Fragment>
                ))}
                <Tr>
                  <Td width={80}>数量</Td>
                  <Td>
                    <Acumin>
                      <select
                        name='quantity'
                        onChange={event => handleQuantityChange(event)}
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                      </select>
                    </Acumin>
                  </Td>
                </Tr>
              </tbody>
            </Table>

            <SpOnly>
              <CarouselWrapper>
                <MyCarousel>
                  <ImageWrapper width={width}>
                    <Image path='egglife-t/5.jpg' />
                  </ImageWrapper>
                  <ImageWrapper width={width}>
                    <Image path='egglife-t/6.jpg' />
                  </ImageWrapper>
                  <ImageWrapper width={width}>
                    <Image path='egglife-t/7.jpg' />
                  </ImageWrapper>
                </MyCarousel>
              </CarouselWrapper>
            </SpOnly>

            <InfoContainer>
              <Info>品番： {variant.sku}</Info>
              <Info>素材： コットン100%</Info>
            </InfoContainer>

            <SizeContainer>
              <Size><span>M</span>着丈<span>73</span>身幅<span>52</span>袖幅<span>22</span>肩幅<span>51</span></Size>
              <Size><span>L</span>着丈<span>80</span>身幅<span>54</span>袖幅<span>23</span>肩幅<span>56</span></Size>
              <Size><span>XL</span>着丈<span>83</span>身幅<span>58</span>袖幅<span>24</span>肩幅<span>61</span></Size>
            </SizeContainer>
          </ProductDetails>

          <CarouselWrapper>
            <PcOnly>
              <MyCarousel>
                <ImageWrapper>
                  <Image path='egglife-t/5.jpg' />
                </ImageWrapper>
                <ImageWrapper>
                  <Image path='egglife-t/6.jpg' />
                </ImageWrapper>
                <ImageWrapper>
                  <Image path='egglife-t/7.jpg' />
                </ImageWrapper>
              </MyCarousel>
            </PcOnly>                                
          </CarouselWrapper>
        </ProductContainer>

        <BtoNoticeContainer>
          <div>完全受注制作</div>
          <div>受注期間：2020年6月23日（火）から7月12日（日）迄の2週間限定  発送時期：2020年8月中旬頃より順次発送予定</div>
          <div>*お客様のご都合による返品・交換はお受けしかねます。予めご了承ください  </div>
        </BtoNoticeContainer>
        
        <ButtonWrapper>
          <AddToCartButton type="submit" onClick={handleAddToCart} disabled={!available || adding || SOLDOUT}>
            <Acumin>SOLD OUT</Acumin>
            <Shadow />
          </AddToCartButton>
        </ButtonWrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
    margin-bottom: 80px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 40px auto 160px;
    width: 960px;
  `}
`

const ProductContainer = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  `}
`

const ProductDetails = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 50px;
    width: 40%;
  `}

  position: relative;
`

const Title = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    span {
      font-size: 8rem;
    }
  `}
`

const Row = styled.div`
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;

  * {
    font-size: 1.4rem;

    :nth-child(7) {
      font-size: 1rem;
      font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
      font-weight: 600;
      font-style: normal;
    }
  }
  
  ${customMedia.greaterThan("medium")`
    display: none;
  `}
`

const Table = styled.table`
  ${customMedia.lessThan("medium")`
    display: none;
  `}

  * {
    font-size: 3rem;
  }

  table-layout: fixed;
`

const Tr = styled.tr`
  ${customMedia.greaterThan("medium")`
    height: 40px;

    :nth-of-type(4) {
      td {
        font-size: 2rem;
      }
    }
  `}

  :nth-of-type(4) {
    font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
`

const Td = styled.td`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
`

const InfoContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    margin-top: 60px;
  `}

  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -1px;
`

const Info = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const SizeContainer = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    width: 190px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 60px;
    width: 380px;
  `}

  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
`

const Size = styled.div`
  ${customMedia.lessThan("medium")`
    height: 25px;
    line-height: 25px;

    span {
      font-size: 1.2rem;
      width: 20px;

      :first-child {
        text-align: center;
        margin-right: 10px;
      }
    }
  `}
  ${customMedia.greaterThan("medium")`
    height: 40px;
    line-height: 40px;

    span {
      font-size: 2.4rem;
      margin-right: 20px;
      width: 34px;

      :first-child {
        text-align: center;
        margin-right: 18px;
      }

      :last-child {
        margin-right: 0;
      }
    }
  `}

  border-bottom: 1px #000 solid;

  :nth-child(3) {
    border: none;
  }
  
  span {
    display: inline-block;
  }

  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: center;
  flex-wrap: center;
  -webkit-align-items: center;
  align-items: center;
`

const CarouselWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 10px 0 0;

    ul.control-dots {
      position: relative;
    }
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 60px;
    width: 50%;

    ul.control-dots {
      position: relative;
      text-align: center;
    }
  `}

  li {
    :focus {
      outline: 0;
    }
  }

  li.slide, li.slide.selected {
    background-color: #fff;
  }
`

const ImageWrapper = styled.div<{width?: number}>`
  ${props => customMedia.lessThan("medium")`
    margin: 0 auto;
    height: ${props.width - 40}px;
    width: ${props.width - 40}px;

    img {
      height: ${props.width - 40}px !important;
      width: ${props.width - 40}px !important;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 400px;
    margin: 0 auto;
  `}
`

const BtoNoticeContainer = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    margin-top: 20px;

    div {
      :nth-child(1) {
        padding: 2px 5px 2.5px;
        margin-bottom: 5px;
        width: 80px;
      }
      :nth-child(2) {
        font-size: 0.8rem;
      }
      :nth-child(3) {
        font-size: 0.6rem;
      }
    }
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 50px;

    div {
      :nth-child(1) {
        padding: 4.5px 10px 6px;
        margin-bottom: 10px;
        width: 120px;
      }
      :nth-child(3) {
        font-size: 1.2rem;
      }
    }
  `}

  div {
    :nth-child(1) {
      background-color: #000;
      color: #fff;
      text-align: center;
    }
  }

  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -0.5px;
`

const ButtonWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  text-align: center;
`

const AddToCartButton = styled.button`
  ${customMedia.lessThan("medium")`
    border: 2px #000 solid;
    height: 26px;
    margin-top: 20px;
    width: 88px;

    span {
      font-size: 1.75rem;
    }
  `}
  ${customMedia.greaterThan("medium")`  
    border: 4px #000 solid;
    height: 45px;
    margin-top: 40px;
    width: 140px;

    span {
      font-size: 3.5rem;
    }
  `}
  
  background-color: #fff;
  cursor: pointer;
  color: #000;
  font-family: 'AcuminVariableConcept';
  font-variation-settings: 'wght' 550, 'wdth' 55;
  font-weight: bold;
  position: relative;
  text-align: center;

  :focus {
    outline: 0;
  }
`

const Shadow = styled.div`
  ${customMedia.lessThan("medium")`
    height: 26px;
    top: 2px;
    left: 2px;
    width: 88px;
  `}
  ${customMedia.greaterThan("medium")`
    height: 45px;
    top: 4px;
    left: 4px;
    width: 140px;
  `}

  background-color: #000;
  position: absolute;
  z-index: -1;
`
