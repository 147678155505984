import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { Image } from '../../common/Image'
import { Acumin } from '../../common/Acumin'
import { SpOnly } from '../../common/SpOnly'
import { PcOnly } from '../../common/PcOnly'

const boxGifSrc = require('../../../images/egglife-t/box.gif')
const videoSrc = require('../../../videos/egglife-t.mp4')

export const EgglifeT = () => {
  const [width, setWidth] = useState(0)
  const [showPhone, setShowPhone] = useState(false)
  const [hidePhone, setHidePhone] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWidth(0)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  const handleClickPlayButton = () => {
    if (!showPhone && !hidePhone) {
      setShowPhone(true)
    } else {
      setShowPhone(!showPhone)
      setHidePhone(!hidePhone)
    }
  }
  
  return (
    <>
      <OverflowHidden>
        <Container>
          <ProductContainer>
            <Image1Wrapper>
              <Image path='egglife-t/1.jpg' />
            </Image1Wrapper>
            <ProductDetails>
              <ProductName>
                <Acumin>EGGLIFE T</Acumin>
              </ProductName>
              <Description1>
                数多のラッパーやアーティストたちが密かに通う神戸のおすし屋さん『磯魚料理・鮨 安さん』のたまご焼き。豊潤なとろ〜りを、本当に伝えたいメッセージだけを発信するNAIJEL GRAPHのやさしいラインで描きました。「EGGLIFE T」をお皿に見立てたスペシャルエディションの真空パッケージには、“good hood”な想いを吹き込んでいます。ICMarketが世界で1番だと思う、食とアートの美味しいコラボレーションをお楽しみください。
              </Description1>
              <BoxGifWrapper>
                <img src={boxGifSrc} alt={boxGifSrc} />
              </BoxGifWrapper>
            </ProductDetails>
          </ProductContainer>

          <ShopContainer>
            <ShopTitle>
              <Acumin light>SHOP</Acumin>
            </ShopTitle>
            <ShopNameWrapper>
              <ShopNameEn>
                <Acumin>YASSAN</Acumin>
              </ShopNameEn>
              <ShopNameJa>
                磯魚料理・鮨 安さん
              </ShopNameJa>
            </ShopNameWrapper>

            <Image2Wrapper>
              <Image path='egglife-t/2.jpg' />
            </Image2Wrapper>

            <Description2>
              明石浦漁港直送の鮮度抜群のお魚料理が食べられる『磯魚料理・鮨 安さん』。地元神戸の人々に愛される老舗名店でありながら、アーティストやラッパーたちが足しげく通うスポットとしても密かな人気を集めています。神戸を訪れると、きっと、“安さん食べた？”と聞かれるはず。そんな皆なが大好きなお寿司屋さんです。
              <Instagram align='right'><span>Instagram:</span> @yassan_sushi_kobe</Instagram>
            </Description2>
            <PlayButton onClick={handleClickPlayButton}>
              <div />
              <div />
            </PlayButton>
            <PhoneImageWrapper width={width} showPhone={showPhone} hidePhone={hidePhone}>
              <Image path='phone@2x.png' />
              <VideoWrapper>
                <video controls>
                  <source src={videoSrc} type="video/mp4" />
                </video>
              </VideoWrapper>
            </PhoneImageWrapper>
          </ShopContainer>

          <ArtistContainer>
            <SpOnly>
              <Image4Wrapper>
                <Image path='egglife-t/4.jpg' />
                <div>※175cm  68kg  Lサイズ着用</div>
              </Image4Wrapper>
            </SpOnly>

            <ArtistDetails>
              <Image3Wrapper>
                <Image path='egglife-t/3.jpg' />
              </Image3Wrapper>

              <ArtistDetailContainer>
                <ArtistTitle>
                  <Acumin light>ARTIST</Acumin>
                </ArtistTitle>
                <ArtistNameEn>
                  <Acumin>NAIJEL GRAPH</Acumin>
                </ArtistNameEn>

                <Description3>
                  グラフィックを中心としたアートワーク事務所。日々楽しくアートワークを創ることを心がけながら活動しています。adidas、BEAMS、ONKYOといった数多くのブランドや企業への作品提供のほか、Beastie Boysオフィシャルグッズを手がけるなど、日本だけではなく海外からも愛されています。  
                  <Instagram><span>Instagram:</span> @naijelgraph</Instagram>
                </Description3>
              </ArtistDetailContainer>
            </ArtistDetails>

            <PcOnly>
              <Image4Wrapper>
                <Image path='egglife-t/4.jpg' />
                <div>※175cm  68kg  Lサイズ着用</div>
              </Image4Wrapper>
            </PcOnly>
          </ArtistContainer>
        </Container>
      </OverflowHidden>
    </>
  )
}

const OverflowHidden = styled.div`
  overflow: hidden;
`

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin: 80px auto 0;
    width: 960px;
  `}
`

const ProductContainer = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
`

const Image1Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    -webkit-flex-basis: 150px;
    flex-basis: 150px;
    margin: 16px;
  `}
  ${customMedia.greaterThan("medium")`
    -webkit-flex-basis: 300px;
    flex-basis: 300px;
  `}

  flex: none;
  position: relative;
`

const ProductDetails = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 110px 0 0 -50px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 120px 0 0 -20px;
  `}

  position: relative;
`

const ProductName = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Description1 = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 15px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    line-height: 30px;
  `}

  font-family: 'TsukuBRdGothic-Regular',sans-serif;
  text-align: justify;
`

const BoxGifWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 10px 0 0 50px;
    width: 130px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 50px 0 0 160px;
    width: 360px;
  `}
`

const ShopContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 80px 0;
  `}
  position: relative;
`

const ShopTitle = styled.div`
  ${customMedia.lessThan("medium")`
    * { font-size: 1rem; }
    height: 8px;
  `}
  ${customMedia.greaterThan("medium")`
    * { font-size: 2rem; }
    height: 12px;
  `}
`

const ShopNameWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  position: relative;
`

const ShopNameEn = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const ShopNameJa = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 0.8rem;
    letter-spacing: -1px;
    top: 12px;
    left: 100px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    letter-spacing: -1.6px;
    top: 28px;
    left: 150px;
  `}

  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  position: absolute;
`

const Image2Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    width: 150px;
  `}
  ${customMedia.greaterThan("medium")`
    width: 360px;
  `}
`

const Description2 = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    margin-top: 10px;
    width: 150px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    line-height: 30px;
    margin-top: 20px;
    width: 480px;
  `}

  font-family: 'TsukuBRdGothic-Regular',sans-serif;
  text-align: justify;
`

const PlayButton = styled.button`
  ${customMedia.lessThan("medium")`
    position: absolute;
    height: 50px;
    top: 80px;
    right: 100px;
    width: 50px;
    
    div:first-child {
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-left: 17.5px solid #fff;
      position: absolute;
      top: 50%;
      left: 55%;
      transform: translateY(-50%) translateX(-50%);
    }

    div:nth-child(2) {
      height: 50px;
      width: 50px;
    }

    @keyframes glow {
      0%, 100% {
        height: 50px;
        width: 50px;
      }
    
      50% {
        height: 60px;
        width: 60px;
      }
    }
  `}
  ${customMedia.greaterThan("medium")`
    height: 80px;
    position: absolute;
    top: 100px;
    right: 260px;
    width: 80px;

    div:first-child {
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-left: 25px solid #fff;
      position: absolute;
      top: 50%;
      left: 55%;
      transform: translateY(-50%) translateX(-50%);
    }

    div:nth-child(2) {
      height: 80px;
      width: 80px;
    }

    @keyframes glow {
      0%, 100% {
        height: 80px;
        width: 80px;  
      }
    
      50% {
        height: 90px;
        width: 90px;  
      }
    }
  `}

  div:nth-child(2) {
    animation: glow 1s infinite;
    animation-duration: 2s;
    background-color: #000;
    border: 1px #000 solid;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: -1;
  }

  background-color: #000;
  border: 1px #000 solid;
  border-radius: 50%;
  cursor: pointer;

  :focus {
    outline: 0;
  }
`

const PhoneImageWrapper = styled.div<{width: number, showPhone: boolean, hidePhone: boolean}>`
  ${props => customMedia.lessThan("medium")`
    top: 40px;
    transform: translateX(${props.width}px);
    width: 150px;
    z-index: 999;

    @keyframes show {
      0% {
        transform: translateX(${props.width}px);
      }
    
      100% {
        transform: translateX(180px);
      }
    }

    @keyframes hide {
      100% {
        transform: translateX(${props.width}px);
      }
    
      0% {
        transform: translateX(180px);
      }
    }
  `}
  ${props => customMedia.greaterThan("medium")`
    top: 0;
    transform: translateX(${props.width}px);
    width: 250px;

    @keyframes show {
      0% {
        transform: translateX(${props.width}px);
      }
    
      100% {
        transform: translateX(600px);
      }
    }

    @keyframes hide {
      100% {
        transform: translateX(${props.width}px);
      }
    
      0% {
        transform: translateX(650px);
      }
    }
  `}

  animation: ${props =>
    props.showPhone || props.hidePhone &&
      props.showPhone ? 'show' : props.hidePhone ? 'hide'
      : 'none'
  } 1s forwards;

  position: absolute;
`

const VideoWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    video {
      width: 138px;
    }
  `}  
  ${customMedia.greaterThan("medium")`
    video {
      width: 232px;
    }
  `}
  
  video {
    display: block;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`

const ArtistContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin-bottom: 50px;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
  `}
`

const ArtistDetails = styled.div`
  ${customMedia.lessThan("medium")`
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  z-index: 99;
`

const ArtistDetailContainer = styled.div`
${customMedia.lessThan("medium")`
  margin-left: 20px;
`}
${customMedia.greaterThan("medium")`
`}

z-index: 99;
`

const ArtistTitle = styled.div`
  ${customMedia.lessThan("medium")`
    * { font-size: 1rem; }
    height: 8px;
  `}
  ${customMedia.greaterThan("medium")`
    * { font-size: 2rem; }
    height: 12px;
    margin-top: 10px;
  `}
`

const ArtistNameWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  position: relative;
`

const ArtistNameEn = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const ArtistNameJa = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 0.8rem;
    letter-spacing: -1px;
    top: 12px;
    left: 100px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    letter-spacing: -1.6px;
    top: 28px;
    left: 150px;
  `}

  font-family: a-otf-midashi-go-mb31-pr6n, sans-serif;
  font-weight: 600;
  position: absolute;
`

const Image3Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 20px;
    width: 120px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 50px;
    width: 300px;
  `}

  flex: none;
  z-index: -1;
`

const Description3 = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 15px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.8rem;
    line-height: 30px;
    width: 560px;
  `}

  font-family: 'TsukuBRdGothic-Regular',sans-serif;
  text-align: justify;
`

const Image4Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 300px;

    div {
      font-size: 8px;
      margin-top: 5px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    margin: -80px 0 0 -100px;
    width: 700px;

    div {
      font-size: 1.4rem;
      margin-top: 10px;
    }
  `}

  flex: none;
  text-align: center;
`

const Instagram = styled.div<{align?: string}>`
  ${customMedia.lessThan("medium")`
    font-size: 0.8rem;
    margin-top: 10px;

    span {
      font-size: 6px;
    }
  `}
  ${props => customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    ${props.align && `text-align: ${props.align}`};
    
    span {
      font-size: 1.2rem;
    }
  `}

  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  text-align: left;

  span {
    font-weight: bold;
  }
`