import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { Image } from '../../common/Image'
import { Acumin } from '../../common/Acumin'
import { SpOnly } from '../../common/SpOnly'
import { PcOnly } from '../../common/PcOnly'

export const BrooklynMachineWorksGangstaUcchieEdition = () => {
  const [width, setWidth] = useState(0)
  const [showPhone, setShowPhone] = useState(false)
  const [hidePhone, setHidePhone] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWidth(0)
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  const handleClickPlayButton = () => {
    if (!showPhone && !hidePhone) {
      setShowPhone(true)
    } else {
      setShowPhone(!showPhone)
      setHidePhone(!hidePhone)
    }
  }

  return (
    <>
      <OverflowHidden>
        <Container>
          <div className="section1">
            <Image path='brooklyn-machine-works-gangsta-ucchie-edition/mainitem.jpg' />

            <div className="content">
              <div className="name">
                <SpOnly>
                  BROOKLYN MACHINE<br />
                  WORKS GANGSTA<br />
                  “UCCHIE” edition
                </SpOnly>
                <PcOnly>
                </PcOnly>
              </div>
              <div className="description">
                トーキョーチャリカルチャーの聖地『W-BASE』から、とてつもないバイクが届きました。<br />『BROOKLYN MACHINE WORKS GANGSTA』。誰もが憧れる名作フレーム（しかもRAWカラー…）をカスタムベースにICMarketだけのスペシャルマシンが完成。BMX FLATLAND世界チャンプUCCHIEと一緒にスーパーエディットした完成車が誕生しました。ツーピースのハンドルもサドルもペダルも、ピストスタイルに贅沢なBMXフレーバーをトッピング。街中クルーズに必須の前後ブレーキも二本引きレバーでお仕立てしました。そんなアッツい日本のバイクカルチャーに麗しのうるおいを。『Tea Bucks』こだわりの茶葉ティーバッグをボトルに入れて走っている間に、ぜいたくな一杯が勝手にフレームバッグの中に出来上がっちゃう。ひとたびまたがると気分ハツラツ。どこにでもイケちゃう飛べちゃうお茶を淹れちゃう。“世界のUCCHIE”エディションのヤンチャな一台をぜひどうぞ。
              </div>
            </div>
          </div>

          <SpOnly>
            <div className="section2">
              <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shopitem1.jpg' />
              <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shopitem2.jpg' />

              <div className="artist">
                <Image path='brooklyn-machine-works-gangsta-ucchie-edition/artist.jpg' />
                <div className="artist-content">
                  <div className="title">BMX Rider</div>
                  <div className="name">Yohei Uchino</div>
                  <div className="description">
                    BMX FLATLAND競技で11度の世界制覇を達成した今なお、飽くなき挑戦をつづけるスーパーヒーロー“UCCHIE”。BMX界の生きる伝説は、いつも新しいトリックで人々をおどろきと感動でつつみ込んで世界中から愛されています。名実ともにトップライダーとして大活躍する一方で、世界最高峰のストリートスポーツ大会『ARK LEAGUE』を主宰。カルチャーの本音を伝える伝道師として、アスリート、メディア、カルチャーの壁を越えて、より大きなステージで人類の未来をになっています。
                  </div>
                  <div className="instagram"><span>Instagram：</span> @uchinoyohei</div>
                </div>
              </div>

              <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shopitem3.jpg' />
              <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shopitem4.jpg' />

              <div className="shop">
                <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shop.jpg' />

                <div className='shop-content'>
                  <div className="title">Bike Shop</div>
                  <div className="name">W-BASE</div>
                  <div className="description">
                    今までどこにもない自転車カルチャーを発信するショップとして超絶なる信頼を集める『W-BASE』。カッコよくて楽しくて、なにより憧れのチャリをお探しなら間違いなくココ。BMXもピストもスケートも。誰にでもどんなカスタムでもぜひ一度ご相談ください。アートやストリートカルチャーにだって明るい最強のスタッフが、きっと心つよい仲間になってくれるはずです。
                  </div>
                  <div className="instagram"><span>Instagram：</span> @wbasebicyclegarag</div>
                </div>
              </div>
            </div>
          </SpOnly>

          <PcOnly>
            <div className="section2">
              <div className="content">
                <Image path='brooklyn-machine-works-gangsta-ucchie-edition/artist.jpg' />

                <div className="title">BMX Rider</div>
                <div className="name">Yohei Uchino</div>
                <div className="description">
                  BMX FLATLAND競技で11度の世界制覇を達成した今なお、飽くなき挑戦をつづけるスーパーヒーロー“UCCHIE”。BMX界の生きる伝説は、いつも新しいトリックで人々をおどろきと感動でつつみ込んで世界中から愛されています。名実ともにトップライダーとして大活躍する一方で、世界最高峰のストリートスポーツ大会『ARK LEAGUE』を主宰。カルチャーの本音を伝える伝道師として、アスリート、メディア、カルチャーの壁を越えて、より大きなステージで人類の未来をになっています。
                </div>
                <div className="instagram"><span>Instagram：</span> @uchinoyohei</div>

                <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shop.jpg' />

                <div className="title">Bike Shop</div>
                <div className="name">W-BASE</div>
                <div className="description">
                  今までどこにもない自転車カルチャーを発信するショップとして超絶なる信頼を集める『W-BASE』。カッコよくて楽しくて、なにより憧れのチャリをお探しなら間違いなくココ。BMXもピストもスケートも。誰にでもどんなカスタムでもぜひ一度ご相談ください。アートやストリートカルチャーにだって明るい最強のスタッフが、きっと心つよい仲間になってくれるはずです。
                </div>
                <div className="instagram"><span>Instagram：</span> @wbasebicyclegarag</div>
              </div>
              <div className="images">
                <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shopitem1.jpg' />
                <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shopitem2.jpg' />
                <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shopitem3.jpg' />
                <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shopitem4.jpg' />
              </div>
            </div>
          </PcOnly>

          <div className="section3">
            <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shopitem5.jpg' />

            <div className="content">
              <Image path='brooklyn-machine-works-gangsta-ucchie-edition/shop2.jpg' />
              <div>
                <div className="title">Tea Stand</div>
                <div className="name">Tea Bucks</div>
                <div className="description">
                  全国各地から厳選した選りすぐりの茶葉を提供するThis is TOKYO’s Tea Stand。性別も国籍も年齢もことなる様々な方一人一人を大切に、一杯のお茶からはじまる贅沢なひと時をお届けしています。お茶がつなげる素晴らしい縁で『Tea Bucks』からニューカルチャーを。新しいコミュニティの場として人と人と時間が出会う場所。そんな最高の日常を教えてくれるお店です。
                </div>
                <div className="instagram"><span>Instagram：</span> @tea_bucks</div>
              </div>
            </div>
          </div>
        </Container>
      </OverflowHidden>
    </>
  )
}

const OverflowHidden = styled.div`
  overflow: hidden;
`

const Container = styled.div`
  @media screen and ( max-width: 768px ) {
    padding: 0 20px;

    .instagram {
      font-size: 0.8rem;
      margin: 5px 0 20px;

      span {
        font-weight: bold;
      }
    }
  }

  @media screen and ( min-width: 769px ) {
    margin: 200px auto 0;
    width: 1000px;

    .instagram {
      font-size: 1.2rem;
      margin: 10px 0 100px;

      span {
        font-weight: bold;
      }
    }
  }

  .title, .name {
    font-family: 'EB Garamond', serif;
    font-weight: bold;
    letter-spacing: -1px;
  }
  
  .title {
    @media screen and ( max-width: 768px ) {
      font-size: 1rem;
    }
    @media screen and ( min-width: 769px ) {
      font-size: 2rem;
      margin-bottom: -14px;
    }
  }

  .name {
    @media screen and ( max-width: 768px ) {
      font-size: 3rem;
    }
    @media screen and ( min-width: 769px ) {
      font-size: 4rem;
    }
  }

  .description {
    font-family: 'TsukuBRdGothic-Regular',sans-serif;

    @media screen and ( max-width: 768px ) {
      font-size: 1.2rem;
    }
    @media screen and ( min-width: 769px ) {
      font-size: 1.5rem;
      line-height: 2.8rem;
    }
  }

  .section1 {
    @media screen and ( min-width: 769px ) {
      .gatsby-image-wrapper {
        margin: 0 auto;
        width: 800px;
      }
    }

    .content {
      @media screen and ( max-width: 768px ) {
      }
      @media screen and ( min-width: 769px ) {
        margin: 0 auto;
        width: 620px;
      }
    }
  }

  .section2 {
    @media screen and ( max-width: 768px ) {
      .artist, .shop {
        display: flex;

        .gatsby-image-wrapper {
          width: 40%;

          img {
            height: auto !important;
            object-fit: fill !important;
          }
        }

        .artist-content, .shop-content {
          padding-left: 10px;
          width: 60%;
        }
      }

      .gatsby-image-wrapper {
        margin-bottom: 20px;
      }
    }
    @media screen and ( min-width: 769px ) {
      display: flex;
      justify-content: space-between;
      margin-top: 100px;

      .content {
        width: 50%;

        .gatsby-image-wrapper {
          margin-bottom: 20px;
          width: 400px;
        }
      }

      .images {
        width: 45%;
        
        .gatsby-image-wrapper {
          margin-bottom: 20px;
        }
      }
    }
  }

  .section3 {
    @media screen and ( max-width: 768px ) {
      .gatsby-image-wrapper {
        margin: 0 auto;
        width: 80%;
      }

      .content {
        display: flex;

        .gatsby-image-wrapper {
          width: 35%;

          img {
            height: auto !important;
            object-fit: fill !important;
          }
        }

        > div {
          padding-left: 20px;
          width: 65%;
        }
      }
    }
    @media screen and ( min-width: 769px ) {
      display: flex;
      justify-content: space-between;
      margin-top: 150px;

      .gatsby-image-wrapper {
        width: 40%;
  
        img {
          height: auto !important;
          object-fit: fill !important;
        }
      }
  
      .content {
        margin-top: -100px;
        width: 53%;
  
        .gatsby-image-wrapper {
          margin-bottom: 20px;
          width: 70%;
        }
      }
    }
  }
`




const ProductContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 40px 20px 0;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
`

const Image4Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    -webkit-flex-basis: 100px;
    flex-basis: 100px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    -webkit-flex-basis: 300px;
    flex-basis: 300px;
  `}

  flex: none;
  position: relative;
`

const ProductDetails = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 0 0 50px;
  `}

  position: relative;
`

const ProductName = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const ProductDescription = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 15px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
  `}

  font-family: 'TsukuBRdGothic-Regular',sans-serif;
  text-align: justify;
`

const BoxGifWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 40px;
    width: 130px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 50px 0 0 160px;
    width: 360px;
  `}
`

const ArtistAndShopContainer = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 50px 0;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-top: 50px;
  `}
`

const ArtistAndShopDetails = styled.div`
  ${customMedia.lessThan("medium")`
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  z-index: 99;
`

const ArtistAndShopDetailContainer = styled.div`
${customMedia.lessThan("medium")`
  margin: 0 20px;
`}
${customMedia.greaterThan("medium")`
`}

z-index: 99;
`

const ArtistAndShopTitle = styled.div`
  ${customMedia.lessThan("medium")`
    * { font-size: 1rem; }
    height: 8px;
  `}
  ${customMedia.greaterThan("medium")`
    * { font-size: 2rem; }
    height: 12px;
    margin-top: 10px;
  `}
`

const ArtistAndShopNameEn = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Image5Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 20px;
    width: 100px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 100px;
    width: 300px;
  `}

  flex: none;
  z-index: -1;
`

const Image6Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 120px;

    div {
      font-size: 8px;
      margin-top: 5px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 280px;
    margin-right: 48px;

    div {
      font-size: 1.4rem;
    }
  `}

  flex: none;
  text-align: center;
`

const Image7Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-left: 20px;
    width: 120px;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 100px;
    width: 300px;
  `}

  flex: none;
  z-index: -1;
`

const ArtistAndShopDescription = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1rem;
    line-height: 15px;
    margin-right: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
    margin-right: 100px;
    width: 468px;
  `}

  font-family: 'TsukuBRdGothic-Regular',sans-serif;
  text-align: justify;
`

const Image8Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 250px;

    div {
      font-size: 8px;
      margin-top: 5px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    width: 400px;
    margin-top: 60px;

    div {
      font-size: 1.4rem;
    }
  `}

  flex: none;
  text-align: center;
`

const Instagram = styled.div<{ align?: string }>`
  ${customMedia.lessThan("medium")`
    font-size: 0.8rem;
    margin-top: 10px;

    span {
      font-size: 6px;
    }
  `}
  ${props => customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    ${props.align && `text-align: ${props.align}`};
    
    span {
      font-size: 1.2rem;
    }
  `}

  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  text-align: left;

  span {
    font-weight: bold;
  }
`